<template>
    <div id="rolesList" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="roles"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="siteusers_roles"></app-sitetitle>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper">
                                    <router-link :to="{ name: 'newSiteRole' }" id="siteusers_createNewRole" class="btn btn-brand btn-bold btn-sm btn-icon-h kt-margin-l-10">
                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                        {{ $t("siteusers_createNewRole") }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile ">
                                        <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                                                            <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" id="Mask" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                            <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" id="Mask-Copy" fill="#000000" fill-rule="nonzero" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("siteusers_defaultRolesList") }}
                                                </h3>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin: Datatable -->
                                            <div class="kt-datatable" id="default_roles_datatable"></div>
                                            <!--end: Datatable -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile ">
                                        <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                                                            <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" id="Mask" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                            <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" id="Mask-Copy" fill="#000000" fill-rule="nonzero" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("siteusers_customRolesList") }}
                                                </h3>
                                            </div>
                                            <div v-if="!isEmptyList" class="kt-portlet__head-toolbar">
                                                <div class="kt-portlet__head-wrapper">
                                                    <div class="kt-portlet__head-toolbar">
                                                        <div class="row align-items-center">
                                                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
                                                                <div class="kt-input-icon kt-input-icon--left">
                                                                    <input type="text" :placeholder="$t('common_searchByRoleName')" id="roles_searchInput" @keyup="onSearchCustomRoles" v-model="searchedValue" class="searchInputDatatable form-control form-control-sm width270" />
                                                                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                                        <span><i class="la la-search"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin emptyList component-->
                                            <app-emptylist targetObjectName="ROLE" mainTextKey="role_notFound" subTextKey="role_notFoundSubLabel" imgName="empty_role.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
                                            <!--end emptyList component-->
                                            <!--begin: Datatable -->
                                            <div class="kt-datatable" id="custom_roles_datatable"></div>
                                            <!--end: Datatable -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import moment from "moment";
import commonVueHelper from "../../helpers/commonVueHelper";
import SiteTitle from "../site/widgets/sitetitle.vue";
import EmptyList from "./../utils/emptylist.vue";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            defaultRolesTable: null,
            customRolesTable: null,
            searchedValue: "",
            isEmptyList: false
        };
    },
    created: function() {
        console.log("Component(Roles)::created() - called");
        if (this.createAnotherRole) {
            this.$router.push({ name: "newSiteRole" });
        } else {
            // Load site roles from backend and store the result in siteroles store.
            this.getSiteRoles(this.siteId);
        }
    },
    mounted: function() {
        console.log("Component(Roles)::mounted() - called");
        KTLayout.init();
    },
    beforeDestroy: function() {
        console.log("Component(Roles)::beforeDestroy() - called");
        // Reset component data
        if (this.defaultRolesTable) {
            this.defaultRolesTable.destroy();
            this.defaultRolesTable = null;
        }
        if (this.customRolesTable) {
            this.customRolesTable.destroy();
            this.customRolesTable = null;
        }
        // Reset store
        this.resetSiteRolesState();
    },
    destroyed: function() {
        console.log("Component(Roles)::destroyed() - called");
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        siteRoles: function() {
            console.log(`Component(RolesTable)::watch(siteRoles) called`);
            this.initKtDataTable();
        },

        user: function(user) {
            if (user) {
                console.log(`Component(RolesTable)::watch(user) called with: ${user}`);
                this.initKtDataTable();
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["siteRoles", "createAnotherRole", "user"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getSiteRoles", "resetSiteRolesState", "showDeleteModal"]),

        onSearchCustomRoles() {
            if (this.customRolesTable) {
                // Search only in one column
                this.customRolesTable.search(this.searchedValue, "name");
            }
        },

        onEditRole: function(roleId) {
            // User clicks on Edit role button in table
            this.$router.push({ name: "editSiteRole", params: { roleId: roleId } });
        },
        registerEventsOnRolesTable(tableType) {
            var self = this;
            if (tableType === "DEFAULT") {
                $("#default_roles_datatable").on("kt-datatable--on-layout-updated", function() {
                    $(".editRoleButton")
                        .off()
                        .on("click", function() {
                            let roleId = $(this).attr("data-roleid");
                            console.log("Component(RolesTable)::registerEventsOnRolesTable(editRoleButton) clicked. RoleId = " + roleId);
                            self.onEditRole(roleId);
                        });
                    // Force width pager size
                    setTimeout(() => {
                        $(".kt-datatable__pager-size").css("width", "80px");
                    }, 50);
                });
            } else if (tableType === "CUSTOM") {
                $("#custom_roles_datatable").on("kt-datatable--on-layout-updated", function() {
                    $(".deleteRoleButton")
                        .off()
                        .on("click", function() {
                            let roleId = $(this).attr("data-roleid");
                            let roleName = $(this).attr("data-rolename");
                            console.log("Component(RolesTable)::registerEventsOnRolesTable(deleteRoleButton) clicked. RoleId = " + roleId);
                            self.showDeleteModal({
                                textContent: i18n.t("siteusers_deleteRoleConfirmText", { roleName: roleName }),
                                actionName: "DELETE_ROLE",
                                params: { roleId: roleId, siteId: self.siteId }
                            });
                        });

                    $(".editRoleButton")
                        .off()
                        .on("click", function() {
                            let roleId = $(this).attr("data-roleid");
                            console.log("Component(RolesTable)::registerEventsOnRolesTable(editRoleButton) clicked. RoleId = " + roleId);
                            self.onEditRole(roleId);
                        });

                    // Force width pager size
                    setTimeout(() => {
                        $(".kt-datatable__pager-size").css("width", "80px");
                    }, 50);
                });
            }
        },

        buildOptionsColumnsForDatatable(tableType) {
            let optionsColumns = [];

            optionsColumns.push({
                field: "name",
                title: i18n.t("siteusers_roleName"),
                textAlign: "center",
                overflow: "visible",
                width: 200,
                template: function(row) {
                    let roleName = row.isDefault ? i18n.t("ROLE_" + row.name) : row.name;
                    return '\
                                <div class="kt-user-card-v2">\
                                    <div class="kt-user-card-v2__details openResourceLink">\
                                        <a class="editRoleButton kt-user-card-v2__name" data-roleid="' + row.id + '" href="javascript:;">' + roleName + "</a>\
                                    </div>\
                                </div>";
                }
            });

            optionsColumns.push({
                field: "features",
                title: i18n.t("siteusers_roleFeatures"),
                sortable: false,
                textAlign: "left",
                overflow: "visible",
                template: function(row) {
                    let featuresList = "";
                    if (row.features) {
                        for (let feature of row.features) {
                            if (feature.isEnabled === true) {
                                featuresList += "- " + i18n.t("ROLE_FEATURES_" + feature.name);
                                let subFeaturesList = "";
                                if (feature.subFeatures) {
                                    for (let subFeature of feature.subFeatures) {
                                        if (subFeature.isEnabled === true && subFeature.name !== "WALKWAY") {
                                            subFeaturesList += i18n.t("ROLE_FEATURES_" + subFeature.name) + ", ";
                                        }
                                    }
                                }
                                if (subFeaturesList !== "") {
                                    featuresList += " (" + subFeaturesList.substring(0, subFeaturesList.length - 2) + ") ";
                                }
                                featuresList += "<br>";
                            }
                        }
                    }
                    return featuresList;
                }
            });

            if (tableType === "CUSTOM") {
                optionsColumns.push({
                    field: "updatedAt",
                    title: i18n.t("common_lastUpdateDate"),
                    sortable: "desc",
                    textAlign: "center",
                    template: function(row) {
                        if (row.updatedAt) {
                            return moment(row.updatedAt).format(commonVueHelper.getDateStringFormatFromLocale(moment().locale()));
                        }
                    }
                });

                optionsColumns.push({
                    field: "Actions",
                    title: i18n.t("common_actions"),
                    sortable: false,
                    width: 110,
                    overflow: "visible",
                    autoHide: false,
                    textAlign: "center",
                    template: function(row) {
                        if (row.isDefault) {
                            // No deletion possible
                            return "-";
                        } else {
                            return (
                                `<button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                i18n.t("common_edit") +
                                `" type="button" data-roleid="` +
                                row.id +
                                `" class="btn btn-outline-brand btn-sm btn-icon editRoleButton">
                                        <i class="la la-pencil"></i>
                                    </button>
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                i18n.t("common_delete") +
                                `" type="button" data-roleid="` +
                                row.id +
                                `" data-rolename="` +
                                row.name +
                                `" class="btn btn-outline-danger btn-sm btn-icon deleteRoleButton">
                                        <i class="la la-trash"></i>
                                    </button>`
                            );
                        }
                    }
                });
            }
            return optionsColumns;
        },

        buildOptionsForDatatable(tableType) {
            let splitRoles = _.partition(this.siteRoles, function(role) {
                return role.isDefault === true;
            });

            let defaultRoles = commonVueHelper.preventHtmlInjection(splitRoles[0]);
            let customRoles = commonVueHelper.preventHtmlInjection(splitRoles[1]);

            const options = {
                data: {
                    type: "local",
                    source: tableType === "DEFAULT" ? defaultRoles : customRoles,
                    pageSize: 5
                },
                responsive: {
                    details: false
                },
                layout: commonVueHelper.getLayoutOptions(),
                translate: commonVueHelper.getTranslateOptions(),
                toolbar: commonVueHelper.getToolbarOptions(),
                sortable: !0,
                pagination: !0,
                columns: this.buildOptionsColumnsForDatatable(tableType)
            };
            return options;
        },

        initKtDataTable() {
            if (
                _.partition(this.siteRoles, function(role) {
                    return role.isDefault === false;
                })[0].length > 0
            ) {
                this.isEmptyList = false;
            } else {
                this.isEmptyList = true;
            }

            const defaultOptions = this.buildOptionsForDatatable("DEFAULT");
            const customOptions = this.buildOptionsForDatatable("CUSTOM");

            // If site table has been already build, destroy the table before creating a fresh instance !
            if (this.defaultRolesTable) {
                $("#default_roles_datatable").KTDatatable().destroy();
                this.defaultRolesTable = null;
            }

            // If site table has been already build, destroy the table before creating a fresh instance !
            if (this.customRolesTable) {
                $("#custom_roles_datatable").KTDatatable().destroy();
                this.customRolesTable = null;
            }

            // According to source list length, show datatable or empty list icon+text
            if (defaultOptions.data.source && defaultOptions.data.source.length > 0) {
                // Now, we are ready to build the table
                this.defaultRolesTable = $("#default_roles_datatable").KTDatatable(defaultOptions);
                // Once table is build, register click events on buttons inside the table
                this.registerEventsOnRolesTable("DEFAULT");
            }

            // According to source list length, show datatable or empty list icon+text
            if (customOptions.data.source && customOptions.data.source.length > 0) {
                // Now, we are ready to build the table
                this.customRolesTable = $("#custom_roles_datatable").KTDatatable(customOptions);
                // Once table is build, register click events on buttons inside the table
                this.registerEventsOnRolesTable("CUSTOM");
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-sitetitle": SiteTitle
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
